import React, { useContext, useEffect, useState } from "react";
import Page from "shared/components/content/Page";
import { Dimmer, Loader, Message } from "semantic-ui-react";
import VerificationStore from "../models/VerificationStore";
import { contexts } from "AppProvider";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

const VerifyEmail = () => {
    const token = new URLSearchParams(useLocation().search).get("token");
    if (!token) {
        throw new Error("Email verification does not have a token");
    }

    const session = useContext(contexts).session;
    const [store] = useState(new VerificationStore(token, session));
    const location = useLocation();

    useEffect(() => {
        if (session.isVerificationRequired && session.isAuthenticated) {
            store.verify();
        }
    }, [session.isAuthenticated]);

    if (!session.isVerificationRequired) {
        return <Navigate replace to="/" />;
    }

    if (!session.isAuthenticated) {
        const returnUrl =
            new URLSearchParams(location.search).get("return") ||
            encodeURIComponent(location.pathname + location.search);
        return <Navigate replace to={`/login?return=${returnUrl}`} />;
    }

    const { failed } = store;

    return (
        <Page title="Verify e-mail">
            <Dimmer active>
                {!failed && <Loader size="large">We're verifying your e-mail...</Loader>}
                {failed && (
                    <Message size="large" error>
                        Oops, there was an error verifying your email.
                    </Message>
                )}
            </Dimmer>
        </Page>
    );
};

export default observer(VerifyEmail);
