import React, { useContext, useState } from "react";
import { contexts } from "AppProvider";
import Page, { HeaderLinks, HeaderLogoWrapper, PageHeader, StyledCard } from "./Page";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Icon, Button, Popup, Card, Header } from "semantic-ui-react";
import logo from "assets/logo.png";
import ItemSearch from "../ItemSearch";
import UserNotifications from "../UserNotifications";
import { Permission } from "shared/models/Permission";
import Authorized from "../navigation/Authorized";
import request from "util/request";
import { observable } from "mobx";
import SessionStore from "shared/models/SessionStore";
import * as Sentry from "@sentry/browser";
import ClientsList from "./ClientsList";
import { dashboardPathByName } from "dashboard/constants";

interface Props {
    children: any;
    breadcrumbs?: any;
    title: string;
    className?: string;
    header?: any;
    grid?: boolean;
    style?: React.CSSProperties;
}

interface Entity {
    id: string;
    name: string;
}

const sessionStore = new SessionStore();

const PrivatePageHeader = observer((props: Props) => {
    const { children, title, className, header, grid, style } = props;
    const session = useContext(contexts).session;
    const logoutUrl = session.useExternalAuth ? "/api/session/external/logout" : "/api/session/logout";
    const [canSeeClients, setCanSeeClients] = useState(false);
    const [entities, setEntities] = useState<Entity[]>([]);
    const navigate = useNavigate();
    return (
        <Page className={className} style={style} grid={grid} title={title}>
            {header}
            {!header && (
                <PageHeader className="page-header">
                    <HeaderLogoWrapper>
                        <Link to="/">
                            <img alt="Aberrant Logo" src={logo} width="36" title="Dashboard" />
                        </Link>
                    </HeaderLogoWrapper>
                    <HeaderLinks>
                        <ItemSearch />
                        {/* <Link to="/standards">Standards</Link> */}
                        {/* <Link to="/controls">Controls</Link> */}
                        <Link to="/docs">Documents</Link>
                        {/* <Link to="/reviews">Reviews</Link> */}
                        <Link to="/actions">Actions</Link>
                        <Link to="/tasks">Tasks</Link>
                        <Link to="/issues">Issues</Link>
                        <Authorized every={[Permission.Audit]}>
                            <Link to="/audits">Audits</Link>
                        </Authorized>
                        <div style={{ width: "1em" }}></div>
                        <UserNotifications />
                        <Link to="/admin/space" aria-label="Settings">
                            <Icon name="cog" title="Settings" />
                        </Link>
                        <div>
                            <Popup
                                position={"bottom right"}
                                basic={true}
                                trigger={
                                    <Icon
                                        name="user"
                                        onClick={() => {
                                            console.log("did anything happen?");
                                            request()
                                                .get(`/api/session/entities`)
                                                .handle((err, res) => {
                                                    if (err) {
                                                        return;
                                                    }

                                                    let entities = res.body;
                                                    let entitiesNotEmpty = false;

                                                    if (entities && Array.isArray(entities) && entities.length > 0) {
                                                        let entitiesCollection = entities
                                                            .sort((a, b) => (a.name < b.name ? -1 : 1))
                                                            .map(e => observable(e));

                                                        setEntities(entitiesCollection);
                                                        entitiesNotEmpty = true;
                                                    }

                                                    setCanSeeClients(entitiesNotEmpty);
                                                })
                                                .catch(err => {
                                                    Sentry.captureException(err);
                                                    setCanSeeClients(false);
                                                });
                                        }}
                                    />
                                }
                                on="click"
                            >
                                <StyledCard showScroll={entities && entities.length > 10 ? "true" : "false"}>
                                    <Card className="custom-card">
                                        <Icon.Group size="huge">
                                            <Icon size="big" name="circle outline" />
                                            <Icon name="user" />
                                        </Icon.Group>
                                        <Card.Content>
                                            <Card.Header>{session.user.name}</Card.Header>
                                            <Card.Meta>{session.user.email}</Card.Meta>
                                            <Card.Meta>Currently logged into: {session.entity.name}</Card.Meta>
                                            <Card.Description>
                                                <Link to="/admin/user">View Users/Roles</Link>
                                                <Header as="h3">Clients:</Header>
                                                {canSeeClients ? (
                                                    <ClientsList
                                                        activeClient={session.entity}
                                                        clients={entities}
                                                        onClick={client =>
                                                            sessionStore.logIntoEntity(client.id, {
                                                                onSuccess: () => {
                                                                    navigate(dashboardPathByName.home);
                                                                }
                                                            })
                                                        }
                                                    />
                                                ) : (
                                                    "Not authorized."
                                                )}
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content extra>
                                            <Button compact as="a" href="/session/changeaccount">
                                                Change Account
                                            </Button>
                                            <Button compact as="a" href={logoutUrl}>
                                                Logout
                                            </Button>
                                        </Card.Content>
                                    </Card>
                                </StyledCard>
                            </Popup>
                        </div>
                    </HeaderLinks>
                </PageHeader>
            )}
            {children}
        </Page>
    );
});

export default PrivatePageHeader;
