import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";
import request from "util/request";
import { forEach } from "lodash";
import { toast } from "react-toastify";
import { Form, Note } from "registration/Styles";
import { Link } from "react-router-dom";
import sessionChangeRedirect from "util/sessionChangeRedirect";

interface FormData {
    token: string;
    registrationUrl: string;
    user: {        
        password: string;
        reEnterPassword: string;
        phoneCountry: number; //1 - US / Canada, 2 - all other
        phone: string;
    };
}

export default ({ user, token, registrationUrl }: FormData) => {
    const {
        handleSubmit,
        control,
        getValues,
        setError,
        watch,
        formState: { isSubmitting, errors }
    } = useForm<FormData>({ defaultValues: { user } });

    const phoneCountryOptions = [
        { key: 1, text: "US / Canadian Phone Number", value: 1 },
        { key: 2, text: "Phone Number outside of the US or Canada", value: 2 }
    ];
    const selectedPhoneCountry = watch('user.phoneCountry');

    const onSubmit = (data: FormData) => {
        return request()
            .post(registrationUrl)
            .send(Object.assign({ token }, data))
            .handle((err, res) => {
                if (err) {
                    if (res?.body?.errors) {
                        forEach(res.body.errors, (message, name) => {
                            setError(name as keyof FormData, { message });
                        });
                    } else {
                        toast.error("There was an error signing up. Please try again later.");
                    }
                    return;
                }

                sessionChangeRedirect();
            })
            .catch(err => {
                // don't bubble these errors to sentry
                if (err.status === 400) {
                } else {
                    throw err;
                }
            });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="registration">        
              <Controller
                name="user.password"
                render={({ field: { value, onChange } }) => {
                    return (
                        <Form.Input
                            value={value}
                            onChange={onChange}
                            label="Password"
                            type="password"
                            error={errors.user?.password && { content: errors.user.password.message }}
                        />
                    );
                }}
                control={control}
                defaultValue=""
                rules={{
                    required: "Password is required",
                    minLength: {
                        value: 10,
                        message: "Password must be at least 10 characters"
                    },
                    pattern: {
                        value: new RegExp("^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{10,}$"),
                        message: "Password must have at least one numeric, alpha, uppercase or non-alpha character+"
                    }
                }}
            />
            <Controller
                name="user.reEnterPassword"
                control={control}
                defaultValue=""
                rules={{
                    required: "Re-entering the password is required",
                    validate: (value) =>
                        value === getValues("user.password") || "Passwords do not match",
                }}
                render={({ field: { value, onChange } }) => (
                    <Form.Input
                        value={value}
                        onChange={onChange}
                        label="Re-enter Password"
                        type="password"
                        error={errors.user?.reEnterPassword && { content: errors.user.reEnterPassword.message }}
                    />
                )}
            />
            <Controller
                name="user.phoneCountry"
                control={control}
                defaultValue={phoneCountryOptions[0]?.value}
                render={({ field: { value, onChange } }) => (
                    <Form.Field
                        control={Dropdown}
                        label="Select phone country"
                        options={phoneCountryOptions}
                        value={value}
                        onChange={(e, { value }) => onChange(value)}
                        selection
                    />
                )}
            />
            <Controller
                name="user.phone"
                render={({ field: { value, onChange } }) => {
                    return (
                        <Form.Input
                            value={value}
                            onChange={onChange}
                            label="Phone"
                            error={errors.user?.phone && { content: errors.user.phone.message }}
                        />
                    );
                }}
                defaultValue=""
                control={control}
                rules={{
                    required: selectedPhoneCountry === 1 ? "Phone number is required" : false,
                    pattern: {
                        value: new RegExp("^\\d{3}-\\d{3}-\\d{4}$"),
                        message: "Phone number should be '###-###-####'+"
                    }
                }}
            />


            <Button primary fluid size="big" type="submit" loading={isSubmitting}>
                Sign Up
            </Button>
            <Note>
                By signing up, you are agreeing to our <Link to="/privacy">Privacy Policy</Link> and{" "}
                <Link to="/terms">Terms of Use</Link>.
            </Note>
        </Form>
    );
};
