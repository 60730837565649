import React from "react";
import { Route, Routes } from "react-router-dom";
import PublicPage from "shared/components/content/PublicPage";
import JiraConnectConfiguration from "./views/JiraConnectConfiguration";
import JiraIntegration from "./views/JiraIntegration";

export default class JiraConnectRoutes extends React.Component<any, {}> {
    render() {
        const { match } = this.props;
        return (
            <Routes>
                <Route path={`${match.url}/configure`} Component={JiraConnectConfiguration} />
                <Route path={`${match.url}/integration`} Component={JiraIntegration} />
            </Routes>
        );
    }
}
