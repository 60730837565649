import React from "react";
 
const PageNotFound = () => {
    return (
        <div>
            <h1>404: Sorry, the resource you are looking for does not exist.</h1>
        </div>
    );
};
 
export default PageNotFound;